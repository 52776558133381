import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TechnologySection = ({ title, techAlt, techImg }) => {
    return (
        <section className="technology-section my-4 mb-3 mb-lg-5 p-3">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs="12" lg="6" className="p-3 p-lg-5">
                        <h1 className="text-primary d-flex flex-column gap-4 big-title fw-bold text-center text-lg-start">
                            Technologies that drives {title}
                        </h1>
                    </Col>
                    <Col xs="12" sm="9" lg="6" className="p-3 p-lg-5">
                        <img src={techImg} alt={techAlt} className="img-fluid" loading="lazy" />
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default TechnologySection