import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./forms.css";
import { useScroll } from "../../context/scroll-context";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import CalendlyEmbed from "../calendly";

const ContactForm = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [recapVal, setRecapVal] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const GOOGLE_TAG_KEY = process.env.REACT_APP_GTAG_CONVERSION_ID;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormValues({ ...formValues, phone: value });
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = "Name is required";
    if (!formValues.company) newErrors.company = "Company is required";
    if (!formValues.email) newErrors.email = "Email is required";
    if (!formValues.phone) newErrors.phone = "Phone number is required";
    if (!selectedOption) newErrors.selectedOption = "Please select an option";
    if (!recapVal) newErrors.recaptcha = "Please complete the reCAPTCHA";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = () => {
    const params = {
      name: formValues.name,
      company_name: formValues.company,
      email: formValues.email,
      phone: formValues.phone,
      lookingfor: selectedOption,
      message: formValues.message,
    };

    axios
      .post("https://acubetechnologies.com:3000/api/sendmail", params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setSuccessMessage(
          "Thank you for reaching out! Your message has been sent successfully."
        );
        setErrorMessage("");
        setIsLoading(false);
        setTimeout(() => {
          setSuccessMessage("");
          setFormValues({
            name: "",
            company: "",
            email: "",
            phone: "",
            message: "",
          });
          setErrorMessage("");
          setSelectedOption("");
        }, 5000);
        const gtag_report_conversion = (url) => {
          // var callback = function () {
          //   if (typeof url !== "undefined") {
          //     window.location = url;
          //   }
          // };

          if (window.gtag) {
            window.gtag("event", "conversion", {
              send_to: GOOGLE_TAG_KEY + "/Gm_UCKrojs8ZEKm3pZQ-",
              event_category: "User",
              event_action: "Form Submission",
              event_label: "Form Button Click",
              // event_callback: callback,
            });
          }

          if (window.dataLayer) {
            window.dataLayer.push({
              event: "form_submission", // Custom event name you can track in GTM
              event_category: "User",
              event_action: "Form Submission",
              event_label: "Form Button Click",
            });
          }

          return false;
        };

        gtag_report_conversion(window.location.pathname);
      })
      .catch((error) => {
        console.error("There was an error sending the email:", error);
        setSuccessMessage("");
        setErrorMessage(
          "Oops! Something went wrong while sending your message. Please try again later."
        );
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      sendEmail();
    } else {
      setSuccessMessage("");
      setErrorMessage(
        "Please make sure all required fields are filled out correctly before submitting the form."
      );
      setIsLoading(false);
    }
  };

  const { contactform } = useScroll();

  return (
    <section className="contact-form-section py-5" id="section1">
      <Container fluid>
        <h1 className="text-center mb-3 text-primary ft-wt-600 text-uppercase" ref={contactform}>
          Apply 2 weeks risk-free trial
        </h1>
        <h2 className="text-center mb-5 text-primary mx-auto ft-28 ft-wt-400" style={{ maxWidth: "1200px" }}>
          Get <span className="ft-wt-600">2 weeks</span> of risk-free developer access by <span className="ft-wt-600">scheduling a meeting</span> directly with our expert engineers, and we'll match you with the best engineer to fit your needs.
        </h2>
        <Row>
          <Col xs="12">
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form onSubmit={handleSubmit} id="footer-contact-form">
              <Row className="mb-30 gap-4 gap-lg-0">
                <Col xs="12" lg="4">
                  <Form.Group controlId="formName">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs="12" lg="4">
                  <Form.Group controlId="formCompany">
                    <Form.Control
                      type="text"
                      placeholder="Company / Organization"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChange}
                      isInvalid={!!errors.company}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.company}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs="12" lg="4">
                  <Form.Group controlId="formEmail">
                    <Form.Control
                      type="email"
                      placeholder="Company Email ID"
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 gap-4 gap-lg-0">
                <Col xs="12" lg="4">
                  <Form.Group controlId="formSelect">
                    <Form.Control
                      as="select"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      isInvalid={!!errors.selectedOption}
                      aria-label="How can we help you?"
                    >
                      <option value="" disabled hidden>
                        How Can We Help You?
                      </option>
                      <option value="Mobile app development">
                        Mobile app development
                      </option>
                      <option value="UI/UX Design">UI/UX Design</option>
                      <option value="Android app development">
                        Android app development
                      </option>
                      <option value="IOS app development">IOS app development</option>
                      <option value="Web app development">Web app development</option>
                      <option value="IOT">IOT</option>
                      <option value="AIML">AIML</option>
                      <option value="QA Automation">QA Automation</option>
                      <option value="RPA">RPA</option>
                      <option value="Others">Others</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.selectedOption}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs="12" lg="4">
                  <Form.Group controlId="formPhone">
                    <PhoneInput
                      country="us"
                      inputProps={{ name: "phone", required: true }}
                      placeholder="Mobile Number*"
                      value={formValues.phone}
                      onChange={handlePhoneChange}
                      isInvalid={!!errors.phone}
                    />
                    {errors.phone && (
                      <div className="text-danger phone-error">
                        {errors.phone}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xs="12" lg="4">
                  <Form.Group controlId="formMessage">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Share Your Message"
                      name="message"
                      value={formValues.message}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center d-flex flex-column gap-4 align-items-center">
                <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={setRecapVal} />
                {errors.recaptcha && <div className="text-danger mt-2">{errors.recaptcha}</div>}
                <Button
                  variant="primary"
                  type="submit"
                  className="text-uppercase gap-2 d-flex align-items-center justify-content-center"
                  disabled={!recapVal}
                >
                  {isLoading && <Spinner size="sm" />}
                  Get Started
                </Button>
              </div>
            </Form>
          </Col>
          <Col xs="12">
            <CalendlyEmbed />
          </Col>
        </Row>
      </Container>
    </section>
  );

};

export default ContactForm;
